.emissions-container {
       width: 75%;

       @media(max-width:768px) {
              width:100%;
       }

       h2 {
              font-family: 'toyota_typelight';
              margin: 0;
              font-weight: 400;
              font-size: 24px;
              color: #000000;
              line-height: 1.3em;
              text-align: center;

              strong {
                     font-family: 'toyota_typeregular';
              }
       }

       h3 {
              font-family: 'toyota_typelight';
              text-align: center;
              font-weight: 400;

              strong {
                     font-family: 'toyota_typeregular';
              }
       }

       .emissions-bar-graph-container {
              width:60%;
              min-width:550px;
              margin: 50px auto 0;

              @media(max-width:768px) {
                     width:100%;
                     min-width:0;
              }

              .emissions-bar-graph {
                     height:400px;

                     canvas {
                            width:100% !important;
                     }

                     p {
                            font-family: 'toyota_typelight';
                            text-align: center;
                            font-size:16px;

                            a {
                                   color:#E10A1D;
                                   text-decoration: underline;
                                   font-weight: 600;

                                   &:after {
                                          position: relative;
                                          top:3px;
                                          left:5px;
                                          content:"";
                                          display: inline-block;
                                          width:12px;
                                          height:12px;
                                          background-repeat: no-repeat;
                                          background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.77093 4.45975C5.91761 4.6006 6 4.79161 6 4.99077C6 5.18993 5.91761 5.38094 5.77093 5.52179L1.34511 9.7707C1.27294 9.84244 1.18661 9.89966 1.09116 9.93902C0.995705 9.97839 0.893043 9.99911 0.789161 9.99997C0.68528 10.0008 0.582259 9.98183 0.486109 9.94407C0.38996 9.9063 0.302607 9.85053 0.229149 9.78001C0.15569 9.70949 0.0975976 9.62563 0.0582597 9.53332C0.0189218 9.44102 -0.000873164 9.34211 2.95397e-05 9.24238C0.000932243 9.14266 0.0225147 9.0441 0.0635176 8.95246C0.10452 8.86083 0.164122 8.77795 0.238846 8.70866L4.11154 4.99077L0.238846 1.27288C0.0963322 1.13122 0.0174745 0.941495 0.019257 0.744562C0.0210396 0.547629 0.10332 0.359247 0.248376 0.219989C0.393433 0.0807308 0.589659 0.00173969 0.794792 2.83931e-05C0.999925 -0.0016829 1.19755 0.0740226 1.34511 0.21084L5.77093 4.45975Z' fill='%23E10A1D'/%3E%3C/svg%3E%0A");
                                          background-size:5px auto;
                                   }
                            }
                     }
              }
       }
}