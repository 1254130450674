.cost-of-ownership-container {
       width:70%;
       align-self: flex-start;

       @media(max-width:768px) {
              width:100%;
       }

       h2 {
              font-family: 'toyota_typelight';
              margin: 0;
              font-weight: 400;
              font-size: 24px;
              color: #000000;
              line-height: 1.3em;
              text-align: center;

              strong {
                     font-family: 'toyota_typeregular';
              }
       }

       h3 {
              font-family: 'toyota_typelight';
              text-align: center;
              font-weight: 400;

              strong {
                     font-family: 'toyota_typeregular';
              }
       }
}