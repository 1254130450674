@font-face {
       font-family: 'toyota_typeregular';
       src: local('toyota_typeregular'),
       url("../src/assets/fonts/ToyotaType-Regular.woff") format('woff');
       font-weight: normal;
       font-style: normal;
}

@font-face {
       font-family: 'toyota_typelight';
       src: local('toyota_typelight'),
       url("../src/assets/fonts/ToyotaType-Light.woff") format('woff');
       font-weight: normal;
       font-style: normal;
}


body, html {
    margin: 0;
    padding: 0;
}

.mobile-only {
       display:none;

       @media(max-width:768px) {
              display:block;
       }
}

.desktop-only {
       @media(max-width:768px) {
              display:none;
       }
}

.main-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100vw; 
    height: auto; 
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.tooltipMain > .tooltip-inner {
       font-family: 'toyota_typeregular';
	padding: 10px !important;
	text-align: center !important;
       color:#fff;
       font-size:13px;
       font-weight: 500;
}
.tooltipBody {
       border-radius: 5px;
	background-color: #1E2123 !important;
}
.tooltipArrow {
	&::before {
		border-right-color: rgba(97, 97, 97, 0.9) !important;
	}
}

.title-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:50px 0 0;

    h1 {
       font-family: 'toyota_typeregular';
       margin:0 0 40px;
       font-size:48px;
       text-align: center;

       @media(max-width:768px) {
              padding:0 15px;
              font-size:26px;
       }
    }

    p {
        font-size: 48px;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: -0.5px;
        text-align: center;
    }
}

.tabs-container {
       display: flex;
       margin-bottom: 56px;

       @media(max-width: 991px) {
              width:100%;
              margin:0 0 60px 0;
              justify-content: center;
              margin-bottom: 24px;
       }

       .tabs {
              width: 100%;
              justify-content: center;
              display: flex;

              button {
                     font-family: 'toyota_typeregular';
                     background-color: transparent;
                     border-top:none;
                     border-right:none;
                     border-left:none;
                     padding:8px 24px;
                     font-size:16px;

                     @media(max-width:768px) {
                            width: 33%;
                     }

                     @media(max-width:560px) {
                            padding:8px 15px;
                            font-size:14px;
                     }
              }
       }
}

.container {
       align-items: flex-start;
       
       @media(max-width:768px) {
              flex-wrap: wrap;
       }
}

.container {
       max-width:1200px;
       margin:0 auto;
       width: 100%;
       display: flex;
       align-items: center;
       justify-content: space-between;
       -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
       box-sizing: border-box; 

    @media(max-width:768px) {
       padding:0 24px;
    }
}

.container-fluid {
       width: 100%;
       display: flex;
       align-items: center;
       justify-content: center;

       @media(max-width: 991px) {
              margin-bottom: 56px;
       }
}

.content-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.filters-container {
       width:225px;
       margin-right:50px;

       @media(max-width:768px) {
              width:100%;
              margin-right:0;
              margin-bottom:50px;
       }

       h2 {
              font-family: 'toyota_typeregular';
              margin:0 0 20px;
              font-weight:600;
              font-size:20px;
              color:#000000;
              line-height: 1em;
       }

       hr {
              border:1px solid #D8D8D8;
              margin:30px 0 25px;
       }

       .input-group-container {
              margin-bottom:25px;

              .input-group-container label {
                     display:none !important;
              }
       }

       .incentive-label-select, .input-group-container label, .incentive-label-select label {
              display: flex;
              margin:0 0 10px;
              font-family: 'toyota_typeregular';
              color:#000000;
              height: 40px;

              &:empty {
                     display:none;
              }

              span {
                     position:relative;
                     top:5px;
                     margin-left:5px;
                     height:20px;
              }
       }

       .zip-code-input-group {
              position:relative;

              .input-group-prepend {
                     position:absolute;
                     top: 55%;
                     transform: translateY(-50%);
                     left:10px;
              }

              input.form-control {
                     font-family: 'toyota_typeregular';
                     width:100%;
                     border-radius: 8px;
                     padding: 16px 45px 16px 35px;
                     border: 1px solid #D8D8D8;
                     background: #fff;
                     font-size: 16px;
                     -webkit-box-sizing: border-box;
                     -moz-box-sizing: border-box;
                     box-sizing: border-box;
              }

              #update-zipcode-button {
                     cursor: pointer;
                     position:absolute;
                     right:0;
                     font-family: 'toyota_typeregular';
                     color:#fff;
                     background-color: #000;
                     border:none;
                     font-size:16px;
                     border-top-right-radius: 8px;
                     border-bottom-right-radius: 8px;
                     padding:20px 10px;
                     line-height: 1em;
              }
       }

       .Range.form-group {
              margin:-10px 0 0;

              input[type="range"] {
                     position: relative;
                     width: 100%;
                     height: 25px;
                     outline: none;
                     background:transparent;
                     border:none;

                     &:before {
                            position: relative;
                            top:7px;
                            content:"";
                            display:block;
                            width:3px;
                            height:12px;
                            background-color:#D8D8D8;
                     }

                     &:after {
                            position: relative;
                            top:7px;
                            content:"";
                            display:block;
                            width:3px;
                            height:12px;
                            background-color:#D8D8D8
                            ;
                     }
              }
       }

       .form-group.currency-wrap {
              position: relative;

              input[type="number"] {
                     position: relative;
                     font-family: 'toyota_typeregular';
                     position: relative;
                     width: 100%;
                     height: 25px;
                     font-size:14px;
                     border-radius: 8px;
                     border: 1px solid #D8D8D8;
                     padding: 25px 45px 25px 20px;
                     -webkit-box-sizing: border-box;
                     -moz-box-sizing: border-box;
                     box-sizing: border-box;
                     font-size:14px;
                     z-index: 4;
              }

              &:before {
                     position: absolute;
                     content: "$";
                     top:14px;
                     left: 10px;
                     font-family: 'toyota_typeregular';
                     color:#000000;
                     z-index: 5;
              }

              &:after {
                     position: absolute;
                     content: " / kWh";
                     top: 14px;
                     left: 50px;
                     font-family: 'toyota_typeregular';
                     color:#000000;
                     z-index: 5;
              }

              .currency-disclaimer {
                     p {
                            font-family: 'toyota_typelight';
                            font-size:12px;
                     }
              }
       }

       .text-danger.small {
              font-family: 'toyota_typeregular';
              font-size:12px;
              line-height: 1em;
       }

       select {
              color:#000000;
              cursor: pointer;
              font-family: 'toyota_typeregular';
              position: relative;
              width:100%;
              border-radius: 8px;
              border: 1px solid #D8D8D8;
              background: #fff;
              padding: 16px 45px 16px 15px;
              font-size: 16px;
              appearance: none;
              background-repeat: no-repeat;
              background-position: 95% 12px;
              font-size:14px;
              background-image: url("data:image/svg+xml,%3Csvg width='32' height='33' viewBox='0 0 32 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25 11.0117L16 20.0117L7 11.0117' stroke='black' stroke-width='3'/%3E%3C/svg%3E");
       }

       select::-ms-expand {
              display: none;
       }

       .incentives-submit-button {
              font-family: 'toyota_typeregular';
              font-size:16px;
              font-weight: 600;
              line-height: 1em;
              width:100%;
              padding:15px 0 17px;
              background-color: #000000;
              color:#ffffff;
              border:none;
              border-radius: 25px;
              cursor: pointer;
       }

       .incentives-disclaimer {
              font-size:12px;
              font-weight: 400;
              font-family: 'toyota_typelight';
              margin-top:25px;
              line-height: 1.2em;
              color:#000000;
       }
}

.disclaimer {
       margin-top: 16px;
       font-family: Roboto;
       font-size: 12px;
       font-weight: 400;
       line-height: 16px;
       letter-spacing: 0px;
       text-align: left;
}

.footer-container {
       width:100%;
       margin:100px 0 0;
       padding:0 0 50px;

       .footer-content {
              display: flex;
              justify-content: center;
              flex-wrap: wrap;

              img {
                     max-width:100%;
              }

              p {
                     font-family: 'toyota_typelight';
                     font-size:12px;
                     text-align: center;
              }
       }
}