@import 'src/client_customizations/scss/client_master.scss';

.IncentiveCard {
       position: relative;
       text-decoration: none;
       margin-right: 32px;
       margin-bottom: 32px;
       border: 1px solid #ccc;
       border-top: 4px solid #E10A1D;
       background-color: white;
       width: 225px;
       height: auto;
       display: flex;
       flex-direction: column;
       padding:24px 15px 50px 15px;

       @media(max-width:768px) {
              width:100%;
              margin:0 0 20px;
       }
}

.IncentiveCardTop {
       padding:0 0 3em;
       text-align: center;
       font-size: 14px;
       color: $IncentiveCard-card-body-color;
       letter-spacing: 0.75px;
       text-transform: uppercase;
       border-radius: 0;
       font-weight: 400;

       .card-title {
              margin-top: 1rem;
              color: $IncentiveCard-card-title-color;
       }

       span {
              font-family: 'toyota_typeregular';
              font-style: normal;
              font-size: 15px;
              font-weight: 400;
              line-height: 16px;
              letter-spacing: 0px;
              text-align: center;
              color: #2a2a2a;
       }
}

.IncentiveCardBody {
       padding: 0rem 0.5rem 0.5rem 0.5rem;
       text-align: center;
       color: $IncentiveCard-card-body-color;
       border-radius: 0;
       margin:0 0 50px 0;

       .card-title {
              height: 55px;
              font-family: 'toyota_typeregular';
              font-size: 24px;
              font-weight: 700;
              line-height: 1em;
              letter-spacing: 0em;
              text-align: center;
              margin-top: 16px;
       }

       .light {
              font-family: 'toyota_typeregular';
              font-size: 18px;
              font-weight: 400;
              line-height: 1.3em;
              letter-spacing: 0.25px;
              text-align: center;
              max-width: 80%;
              margin: 24px auto 0;
       }

       img {
              margin-top: -12px;
              margin-bottom: -16px;
              width: 60px;
       }

}
.IncentiveCardBottom {
       width: 100%;
       color: #000000;
       font-size: 14px;
       font-weight: 400;
       padding:0;
       position: absolute;
       bottom:25px;
       left:0;
       box-sizing: border-box;

       @media (max-width: 992px) {
              padding: 20px 0 0;
       }

       &.active {
              position: relative;
       }

       .IncentiveCardButtonLinks {
              display:flex;
              padding:0 5px;

              @media(max-width:450px) {
                     flex-direction: column;
                     justify-content: center;
              }

              a, button {
                     padding:0;
                     width:50%;
                     font-family: 'toyota_typeregular';
                     font-size: 16px;
                     font-weight: 700;
                     line-height: 1em;
                     letter-spacing: 0px;
                     text-align: center;
                     color: #000;
                     text-decoration: none;

                     @media(max-width:450px) {
                            width:100%;
                     }
              }

              a {
                     border-right:1px solid #D8D8D8;
                     color:#E10A1D;

                     @media(max-width:450px) {
                            border-right: none;
                            margin:0 0 5px;
                     }

                     &:after {
                            position: relative;
                            top:0px;
                            left:5px;
                            content:"";
                            display: inline-block;
                            width:12px;
                            height:12px;
                            background-repeat: no-repeat;
                            background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.77093 4.45975C5.91761 4.6006 6 4.79161 6 4.99077C6 5.18993 5.91761 5.38094 5.77093 5.52179L1.34511 9.7707C1.27294 9.84244 1.18661 9.89966 1.09116 9.93902C0.995705 9.97839 0.893043 9.99911 0.789161 9.99997C0.68528 10.0008 0.582259 9.98183 0.486109 9.94407C0.38996 9.9063 0.302607 9.85053 0.229149 9.78001C0.15569 9.70949 0.0975976 9.62563 0.0582597 9.53332C0.0189218 9.44102 -0.000873164 9.34211 2.95397e-05 9.24238C0.000932243 9.14266 0.0225147 9.0441 0.0635176 8.95246C0.10452 8.86083 0.164122 8.77795 0.238846 8.70866L4.11154 4.99077L0.238846 1.27288C0.0963322 1.13122 0.0174745 0.941495 0.019257 0.744562C0.0210396 0.547629 0.10332 0.359247 0.248376 0.219989C0.393433 0.0807308 0.589659 0.00173969 0.794792 2.83931e-05C0.999925 -0.0016829 1.19755 0.0740226 1.34511 0.21084L5.77093 4.45975Z' fill='%23E10A1D'/%3E%3C/svg%3E%0A");
                            background-size:7px auto;
                     }
              }

              button.showBtn {
                     cursor: pointer;
                     text-align: center;
                     border: none;
                     background-color: transparent;

                     &:after {
                            position: relative;
                            top:0px;
                            left:5px;
                            content:"";
                            display: inline-block;
                            width:12px;
                            height:12px;
                            background-repeat: no-repeat;
                            background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.77093 4.45975C5.91761 4.6006 6 4.79161 6 4.99077C6 5.18993 5.91761 5.38094 5.77093 5.52179L1.34511 9.7707C1.27294 9.84244 1.18661 9.89966 1.09116 9.93902C0.995705 9.97839 0.893043 9.99911 0.789161 9.99997C0.68528 10.0008 0.582259 9.98183 0.486109 9.94407C0.38996 9.9063 0.302607 9.85053 0.229149 9.78001C0.15569 9.70949 0.0975976 9.62563 0.0582597 9.53332C0.0189218 9.44102 -0.000873164 9.34211 2.95397e-05 9.24238C0.000932243 9.14266 0.0225147 9.0441 0.0635176 8.95246C0.10452 8.86083 0.164122 8.77795 0.238846 8.70866L4.11154 4.99077L0.238846 1.27288C0.0963322 1.13122 0.0174745 0.941495 0.019257 0.744562C0.0210396 0.547629 0.10332 0.359247 0.248376 0.219989C0.393433 0.0807308 0.589659 0.00173969 0.794792 2.83931e-05C0.999925 -0.0016829 1.19755 0.0740226 1.34511 0.21084L5.77093 4.45975Z' fill='black'/%3E%3C/svg%3E%0A");
                            background-size:7px auto;
                     }
              }
       }

       .IncentiveCardDetails {
              margin-top:20px;

              p.h3 {
                     font-family: 'toyota_typeregular';
                     margin:0;
                     line-height: 1.3em;
              }
       }
}

.displayNone {
       display: none !important;
}

.showAllCard {
       min-height: 369px !important;
       height: auto !important;
       max-height: 1000px !important;

       .description {
              min-height: 88px !important;
              height: auto !important;
              max-height: 1000px !important;
       }
}

@media (min-width: 768px) {
       .IncentiveCardBody {
              padding: 0rem 0.5rem 0.5rem 0.5rem;
       }
}